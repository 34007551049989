import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatSliderModule } from '@angular/material/slider'
import { ColorUtilsService } from '../utils/color-utils.service'
import { ChiliColorPickerComponent } from './chili-color-picker.component'

@NgModule({
    declarations: [ChiliColorPickerComponent],
    exports: [ChiliColorPickerComponent],
    imports: [CommonModule, FormsModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatSliderModule],
    providers: [ColorUtilsService],
})
export class ChiliColorPickerModule {}
